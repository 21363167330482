export const telNumber = "+7(918)944-55-48";
export const telNumberClear = "79189445548";
export const tg_contact = "Oksana_Arzumanyan";
export const email = "007oks@mail.ru";
export const firstName = "Оксана";
export const lastName = "Арзуманян";
export const fatherName = "Ванушевна";
export const aboutPerson = "Врач-эксперт";
export const registrationLink = "https://ru.siberianhealth.com/ru/shop/user/registration/PRIVILEGED_CLIENT/?referral=2599119521"

export const LOCAL_LINKS = [
    {
        link: "home_l",
        name: "Главная",
    },
    {
        link: "about_l",
        name: "О нас",
    },
    {
        link: "strategy_l",
        name: "Стратегии",
    },
    {
        link: "reviews_l",
        name: "Отзывы",
    },
];

export const REVIEWS = [
    {
        id: 45542,
        rating: 5,
        review: `Впервые попробовала бальзам, у меня болит нога, боль даёт варикоз. С первого нанесения бальзама боль стала тише, за день мазала ногу несколько раз, результатом очень довольна. При головной боли мажу виски, лоб, шею, боль затихает. Мажу бальзамом ноги перед работой, ощущение лёгкости в ногах. Бальзам приятно пахнет, хорошо впитывается. Отличный бальзам! Благодарю компанию за такой продукт! `,
        person: {
            name: "Барбашова Татьяна Анатольевна",
            avatar: "/images/user_0.jpg",
        },
    },
    {
        id: 21693,
        rating: 5,
        review: "Хочу оставить отзыв о продукте. У сына очень шелушились ладони и стопы, были микротрещины и сильные покраснения, особенно на ладонях, пили много витаминов и мазали разными мазями, всё бесполезно, я решала давать ему по утрам натощак по половине чайной ложки, всё прошло, была удивлена. Так что продолжаем пить его дальше.",
        person: {
            name: "Ольга Ларионова",
            avatar: "/images/user_1.jpg",
        },
    },
    {
        id: 245,
        rating: 5,
        review: "Пью Новомин с конца сентября, и сейчас уже 11 января, на работе и вокруг все болеют, но я как огурчик в строю, пью по одной каждый день, но был момент когда муж начал заболевать, я начала принимать 5 капсул утром и 5 вечером, дня три и все норм. Муж не верил в продукт, переболел в лёгкую, без температуры, и теперь тоже пьёт. И ещё капаем эпам 900. Мне нравится. Советую для профилактики.",
        person: {
            name: "Романова Людмила",
            avatar: "/images/user_2.jpeg",
        },
    },
];

export const SPECIAL_DATA = [
    {
        icon: "😀",
        text: "Избавиться от хронической усталости и стресса",
        color: "#FC374E",
    },
    {
        icon: "😁",
        text: "Нормализовать сон",
        color: "#D468F9",
    },

    {
        icon: "😉",
        text: "Укрепить иммунитет",
        color: "#24D366",
    },
    /*     {
        icon: "😏",
        text: "Наладить работу  ЖКТ( запоры, изжога, вздутие и тд)",
        color: "#36AEB3",
    },
    {
        icon: "😎",
        text: "Избавится от разного вида  высыпаний на кожи",
        color: "#F15F0E",
    },
    {
        icon: "😺",
        text: "Избавиться от болей в суставах",
        color: "#162D59",
    },
    {
        text: "Снизить вес",
        icon: "😽",
        color: "#36AEB3",
    }, */
    {
        text: "Замедлить процессы старение, омолодиться изнутри",
        icon: "😻",
        color: "#FC374E",
    },
    {
        text: "Грамотно проходить программы детокса и чистки от паразитов и многое другое.",
        color: "#36AEB3",
        icon: "💖",
    },
];

