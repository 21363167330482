import { SPECIAL_DATA } from "../../constants/data";

function About_2() {
    return (
        <div id="about_2_l" className="about_2">
            <div className="title">Вы в нужном месте, если хотите:</div>
            <div className="list">
                <ol className="gradient-list">
                    {SPECIAL_DATA.map(({ color, icon, text }, index) => {
                        return <li key={index}>{text}</li>;
                    })}
                </ol>
            </div>
        </div>
    );
}

export default About_2;
