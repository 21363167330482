import { registrationLink } from "../../constants/data";

export default function СomeRegistration() {
    return (
        <div className="come_registration">
            <div className="overlay" />
            <div className="content">
                <div className="title">Открой секрет счастливого образа жизни! 😊</div>
                <div className="desc">
                    Познай все преимущества уникального сообщества! Присоединись к нам и начни строить свой
                    собственный путь к успеху и счастью! Нажми кнопку 'зарегистрироваться' и открой дверь в
                    мир бесконечных возможностей прямо сейчас!
                </div>
                <a href={registrationLink} className="reg_button">
                    Зарегистрироватья!
                </a>
            </div>
        </div>
    );
}
