// import Swiper core and required modules
import { A11y, EffectCube, FreeMode, Navigation, Pagination, Thumbs } from "swiper/modules";

import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";

// Import Swiper styles
import { useMemo, useState } from "react";

import "swiper/css";
import "swiper/css/effect-cube";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/thumbs";

function About() {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [play, setPlay] = useState(false);
    const urlVideo = ["videos/video_0.mp4", "videos/video_1.mp4", "videos/video_4.mp4", "videos/video_5.mp4"];

    function pauseAllVideos() {
        const elems = document.querySelectorAll(".custom_wideo_player") as NodeListOf<HTMLVideoElement>;
        elems.forEach((elem) => {
            elem.pause();
        });
        setPlay(false);
    }

    function onClickPlay(
        { activeIndex, slides }: SwiperClass,
        event: MouseEvent | TouchEvent | PointerEvent
    ) {
        const target = slides[activeIndex].querySelector("video") as HTMLVideoElement;
        play && pauseAllVideos();

        play ? target.pause() : target.play();
        setPlay(!play);
    }

    function onChangeSlide() {
        pauseAllVideos();
    }

    const slides = useMemo(() => {
        return urlVideo.map((src, index) => {
            return (
                <SwiperSlide data-id={`${src}_i_${index}`} key={src} className="slide_gallery player">
                    <video className="custom_wideo_player" width="100%" id={`${src}_i_${index}`} src={src} />
                    <div className={`${play ? "play" : "pause"} play_button`} />
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white" />
                </SwiperSlide>
            );
        });
    }, [play]);

    return (
        <div id="about_l" className="about">
            <div className="text_content">
                <h2 className="title">Обо мне и нашей команде</h2>
                <div className="description">
                    <div>
                        Здравствуйте, меня зовут Арзуманян Оксана Ванушевна, работаю врачом не один год,
                        врачом хирургом, терапевтом, инфекционистом и врачом превентивной медицины, и, сегодня
                        я расскажу вам о нашей компании.
                    </div>
                    <div>
                        Двадцать семь лет назад Корпорация “Сибирское здоровье” вышла на рынок со смелой
                        идеей: предложить продукты для поддержания здоровья и красоты, созданные на основе
                        диких сибирских растений. На её стороне были перспективные научные разработки в
                        области хронобиологии, эндоэкологии и адаптационной медицины, а также все богатства
                        заповедного сибирского края. С тех пор миллионы людей в десятках стран стали
                        поклонниками продукции «Сибирского здоровья»!
                    </div>
                </div>
            </div>
            <div className={`slider_container ${play ? "play" : "pause"}`}>
                <Swiper
                    // install Swiper modules
                    modules={[Pagination, A11y, EffectCube, FreeMode, Thumbs]}
                    spaceBetween={50}
                    slidesPerView={1}
                    pagination={{ clickable: true }}
                    effect={"cube"}
                    grabCursor={true}
                    loop={true}
                    //@ts-ignore
                    thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                    style={{
                        //@ts-ignore
                        "--swiper-navigation-color": "#fff",
                        "--swiper-pagination-color": "#fff",
                    }}
                    className="swiper_view"
                    onSlideChange={onChangeSlide}
                    onClick={onClickPlay}
                >
                    {slides}
                </Swiper>
                <Swiper
                    //@ts-ignore
                    onSwiper={setThumbsSwiper}
                    spaceBetween={10}
                    slidesPerView={4}
                    freeMode={true}
                    watchSlidesProgress={true}
                    modules={[FreeMode, Navigation, Thumbs]}
                    className="swiper_preview"
                >
                    {slides}
                </Swiper>
            </div>
        </div>
    );
}

export default About;
