function Gallery() {
    return (
        <div className="gallery">
            <div className="gallery_container">
                <img src="images/bg_line.png" className="bg_line" />
                <div id="media_0">
                    <img src="images/sib_2.jpg" />
                </div>
                <div id="media_1">
                    <img src="images/sib_8.jpg" />
                </div>
                <div id="media_2">
                    <img src="images/sib_5.jpg" />
                </div>
                <div id="media_3">
                    <img src="images/sib_6.jpg" />
                </div>
            </div>
        </div>
    );
}

export default Gallery;
