import { LOCAL_LINKS } from "../../constants/data";
import { useHash } from "../../hooks/useHash";
import Socials from "../ui/Socials";

function Footer() {
    const { hash, renewHash } = useHash();
    return (
        <div id="contacts_l" className="footer">
            <h2>Есть какие-нибудь вопросы?</h2>
            <div className="have_questions">
                Ещё не уверены в своём выборе? <br />
                Давайте созвонимся, чтобы узнать друг друга получше!
            </div>
            <Socials direction="row" />
            <div className="links">
                {LOCAL_LINKS.map(({ link, name }) => {
                    return (
                        <a
                            href={`#${link}`}
                            key={link}
                            className={`local_link ${hash === link ? "active" : ""}`}
                            onClick={renewHash}
                        >
                            {name}
                        </a>
                    );
                })}
            </div>
            <div className="line" />
            <div className="rights">© Все права защищены</div>
        </div>
    );
}

export default Footer;
