import { Dispatch, SetStateAction } from "react";
import { LOCAL_LINKS, registrationLink } from "../../constants/data";
import { useHash } from "../../hooks/useHash";
import { classHtmlBodyWithMenu } from "../sections/Header";
import Logo from "./Logo";
import Socials from "./Socials";

interface IMenu {
    setMenuOpen: Dispatch<SetStateAction<boolean>>;
}

function Menu({ setMenuOpen }: IMenu) {
    const { hash } = useHash();

    const onClickMenu = () => {
        classHtmlBodyWithMenu(false);
        setMenuOpen(false);
    };

    return (
        <div className="menu">
            <div className="overlay" onClick={onClickMenu} />
            <div className="menu_container">
                <div className="top_block">
                    <div className="logo_container">
                        <Logo />
                    </div>
                    <div className="links">
                        {LOCAL_LINKS.map((link, index) => {
                            return (
                                <a
                                    href={`#${link.link}`}
                                    className={`${
                                        link.link === hash || (hash === "" && index === 0) ? "active" : ""
                                    }`}
                                    key={link.link}
                                    onClick={onClickMenu}
                                >
                                    {link.name}
                                </a>
                            );
                        })}
                    </div>
                </div>

                <div className="menu_sosials">
                    <div className="socials_block">
                        <Socials />
                    </div>
                    <a href={registrationLink} className="reg_button">
                        Зарегистрироватья
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Menu;
