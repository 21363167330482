import { PiStrategyBold } from "react-icons/pi";

function Title() {
    return (
        <div id="title_l" className="Title">
            <div className="title_container">
                <h1 className="title">Siberian Wellness – для тех, кто хочет жить ярко!</h1>
                <div className="description">
                    Честный бизнес для wellness-предпринимателей: международные возможности, ответственный{" "}
                    <br />
                    экоподход, глобальное производство инновационных продуктов для здоровья, спорта и красоты.
                </div>
            </div>
            <a href="#strategy_l" className="services_link">
                Наши стратегии <PiStrategyBold />
            </a>
        </div>
    );
}

export default Title;
