function RowGallery() {
    const urlMedia = [
        "images/photo_0.jpg",
        "images/photo_1.jpg",
        "images/photo_2.jpg",
        "images/photo_3.jpg",
        "images/photo_4.jpg",
        "images/photo_0.jpg",
        "images/photo_1.jpg",
        "images/photo_2.jpg",
        "images/photo_3.jpg",
        "images/photo_4.jpg",
    ];

    return (
        <div className="row_gallery">
            <div className="gallery_container">
                {urlMedia.map((media, index) => {
                    return (
                        <div
                            style={{
                                backgroundImage: `url(${media})`,
                            }}
                            className="media"
                            key={media + index}
                        />
                    );
                })}
            </div>
        </div>
    );
}

export default RowGallery;
