import { Rating } from "react-simple-star-rating";
import { REVIEWS } from "../../constants/data";

function Reviews() {
    return (
        <div id="reviews_l" className="reviews_container">
            <h2>Посмотрите, что говорят клиенты</h2>
            <div className="reviews">
                {REVIEWS.map(({ id, person, rating, review }, index) => {
                    return (
                        <div key={id} className="review">
                            <div className="rating">
                                <Rating
                                    className="rating"
                                    initialValue={5}
                                    readonly
                                    size={30}
                                    SVGstyle={{
                                        fill: "#FFBC0B",
                                    }}
                                />
                            </div>
                            <div className="description">{review}</div>
                            <div className="user">
                                <div
                                    style={{
                                        backgroundImage: `url(${person.avatar})`,
                                    }}
                                    className="avatar"
                                />
                                <div className="name">{person.name}</div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}
export default Reviews;
