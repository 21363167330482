import { FaHandPointLeft } from "react-icons/fa";

function Strategy() {
    return (
        <div id="strategy_l" className="strategy">
            <h2>Наши стратегии</h2>
            <ul className="list">
                <li>
                    <div className="title_li">Эндоэкология</div>
                    <div className="desc_li">
                        <div>
                            Эта наука разрабатывает методы для обеспечения нормальной работы организма. Цель
                            метода эндоэкологии - не лечение, а профилактика заболеваний.
                        </div>
                        <FaHandPointLeft size={30} className="point" />
                    </div>
                </li>
                <li>
                    <div className="title_li">Адаптационная медицина</div>
                    <div className="desc_li">
                        <div>
                            В ходе своей истории мы смогли использовать природные возможности для адаптации,
                            вместо того, чтобы полагаться только на наш организм.
                        </div>
                        <FaHandPointLeft size={30} className="point" />
                    </div>
                </li>
                <li>
                    <div className="title_li">Хронобиология</div>
                    <div className="desc_li">
                        <div>
                            Изучением биоритмов и здоровьем человека и взаимоотношениями между ними занимается
                            такая отрасль биологии как хронобиология
                        </div>
                        <FaHandPointLeft size={30} className="point" />
                    </div>
                </li>
            </ul>
        </div>
    );
}

export default Strategy;
