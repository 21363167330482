import About from "./components/sections/About";
import About_2 from "./components/sections/About_2";
import Footer from "./components/sections/Footer";
import Gallery from "./components/sections/Gallery";
import Header from "./components/sections/Header";
import Reviews from "./components/sections/Reviews";
import RowGallery from "./components/sections/RowGallery";
import Strategy from "./components/sections/Strategy";
import Title from "./components/sections/Title";
import СomeRegistration from "./components/sections/СomeRegistration";
import "./styles/App.scss";

export default function App() {
    return (
        <div className="App">
            <Header />
            <Title />
            <Gallery />
            <Strategy />
            <About />
            <RowGallery />
            <About_2 />
            <Reviews />
            <СomeRegistration />
            <Footer />
        </div>
    );
}
