import { IoIosMail } from "react-icons/io";
import { email, telNumberClear, tg_contact } from "../../constants/data";

interface ISocials {
    direction?: "row" | "column";
}

function Socials({ direction }: ISocials) {
    return (
        <div
            style={{
                flexDirection: direction,
            }}
            className="socials"
        >
            <a href={`https://wa.me/${telNumberClear}`} rel="noopener noreferrer" data-messenger="whatsapp">
                <img
                    src="https://lookme.icu/app/assets/templates/basic/images/whatsapp.svg"
                    alt="whatsapp.svg icon"
                />
            </a>
            <a href={`tg://resolve?domain=${tg_contact}`} rel="noopener noreferrer" data-messenger="telegram">
                <img
                    src="https://lookme.icu/app/assets/templates/basic/images/telegram.svg"
                    alt="telegram.svg icon"
                />
                <span></span>
            </a>
            <a href={`mailto:${email}`} rel="noopener noreferrer" data-messenger="email">
                <IoIosMail size={26} />
            </a>
        </div>
    );
}

export default Socials;
