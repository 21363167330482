import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiMenu3Fill } from "react-icons/ri";
import { aboutPerson, fatherName, firstName, lastName } from "../../constants/data";
import Logo from "../ui/Logo";
import Menu from "../ui/Menu";

export function classHtmlBodyWithMenu(state?: boolean) {
    const className = "menu_opened";
    const html = document.getElementsByTagName("html")[0];

    if (typeof state === "boolean") {
        if (state) {
            document.body.classList.add(className);
            html.classList.add(className);
        } else {
            document.body.classList.remove(className);
            html.classList.remove(className);
        }
        return;
    }

    if (document.body.classList.contains(className) || html.classList.contains(className)) {
        document.body.classList.remove(className);
        html.classList.remove(className);
    } else {
        document.body.classList.add(className);
        html.classList.add(className);
    }
}

export default function Header() {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div id="home_l" className="header">
            <div className="header_container">
                <div className="l_block">
                    <Logo/>
                </div>
                <div className="r_block">
                    {!menuOpen ? (
                        <RiMenu3Fill
                            className="burger-menu"
                            size={35}
                            onClick={() => {
                                setMenuOpen(!menuOpen);
                                classHtmlBodyWithMenu(!menuOpen);
                            }}
                        />
                    ) : (
                        <AiOutlineClose
                            className="burger-menu"
                            size={35}
                            onClick={() => {
                                setMenuOpen(false);
                                classHtmlBodyWithMenu(false);
                            }}
                        />
                    )}
                </div>
                <div className="my_name">
                    <div className="l_block">
                        <img src="images/person_0.png" alt="person" />
                    </div>
                    <div className="r_block">
                        <div className="my_name_container">
                            <span className="last_name">{lastName}</span>
                            <span className="first_name">{firstName}</span>
                            <span className="father_name">{fatherName}</span>
                        </div>
                        <div className="about_person">{aboutPerson}</div>
                    </div>
                </div>
            </div>
            {menuOpen ? <Menu setMenuOpen={setMenuOpen} /> : ""}
        </div>
    );
}
